<template>
  <Layout navbarTransparent>
    <div class="container mt-5"> 
      <div class="row">
        <div class="col-lg-auto" style="max-width: 350px">
          <div class="card">
            <div class="card-body">
              <h4 class="font-weight-bolder">Find Insight</h4>
              <b-form-group class="mt-1">
                <b-input-group class="input-group-merge">
                  <b-form-input placeholder="Pencarian" v-model.lazy="search" />
                  <b-input-group-append is-text>
                    <i class="bx bx-search" style="cursor: pointer" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <hr />
              <h4 class="font-weight-bolder mb-1">Categories</h4> 

              <div class="d-flex flex-wrap" style="gap: 10px">
                <b-button
                  @click="toggleGenre(item.genre_id)"
                  v-for="item in genres"
                  :key="item.genre_id"
                  :variant="selected_genres.includes(item.genre_id) ? 'primary' : 'warning'"
                  style="color:white !important"
                  size="sm"
                >
                  {{item.genre_name}}
                </b-button>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="font-weight-bolder mb-2">Highlights</h4>
              <router-link :to="`/insight/article/${data.article_id}`" v-for="(data,i) in articleHighlight" :key="data.article_id">
                <div :class="`${i != articleHighlight.length - 1 ? 'mb-2': '' }`">
                  <img class="w-100 rounded" :src="data.article_image" />
                  <div class="mt-1">
                    <h5 class="text-warning font-weight-bolder mb-1">
                      {{data.article_title}}
                    </h5>
                    <div class="d-flex justify-content-between font-weight-bolder">
                      <label class="text-truncate mr-2"
                        >{{data.user_fullname}} -</label
                      >
                      <label class="text-nowrap">{{moment(data.article_lastupdate).fromNow()}}</label>
                    </div>
                    <div v-html="stripHtml(data.article_content)" class="article-content text-secondary p-0 ql-editor"></div>
                  </div>
                </div>
              </router-link> 
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="font-weight-bolder mb-1">Connect with us</h4>
              <social-medias />

            </div>
          </div>
        </div>
        <div class="col-lg px-3">
          <b-tabs class="w-100 mb-5" v-if="pageType == 'all'">
            <article-tab :genres="selected_genres" :search="search" />
            <soundboard-tab :genres="selected_genres" :search="search" />
          </b-tabs>
          <article-detail v-else-if="pageType == 'article'" />
          <soundboard-detail v-else-if="pageType == 'soundboard'" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import moment from 'moment'
import store from '@/store'
import SocialMedias from '@/components/SocialMedias.vue'

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

import ArticleTab from "./insight/article-tab.vue";
import ArticleDetail from "./insight/article-detail.vue";
import SoundboardTab from "./insight/soundboard-tab.vue";
import SoundboardDetail from "./insight/soundboard-detail.vue";

export default {
  components: {
    SocialMedias,
    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
    ArticleTab,
    ArticleDetail,
    SoundboardTab,
    SoundboardDetail
  },
  data() {
    return {
      pageType: "all",
      moment,
      Id: null,
      page: "",
      genres: [],
      selected_genres: '',
      articleHighlight: [],
      search: "", 
    };
  },
  computed: {
    loading() {
      return store.state.loading;
    },
  },
  methods: {
    toggleGenre(genre) {
      if(this.selected_genres.includes(genre)) {
        this.selected_genres = this.selected_genres.replace(`${genre},`, '')
      } else {
        this.selected_genres += `${genre},`
      }
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
  },
  watch: { 
    $route(to, from) {
      this.$forceUpdate();
      this.pageType = this.$route.params.type ? this.$route.params.type : "all";
    },
  },
  async mounted() {
    this.pageType = this.$route.params.type ? this.$route.params.type : "all"; 
    this.genres = await store.dispatch(`genres/getDataList`)    
    this.articleHighlight = await store.dispatch(`articles/getHighlight`) 
  },
};
</script>

<style>
.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}
</style>
