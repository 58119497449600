<template>
    <b-tab title="Articles" active> 
        <div class="row">
            <div class="col-lg-6" v-for="item in data_list" :key="item.article_id">
                <article-card :data="item" />
            </div>
        </div>
        <div class="d-flex w-100">
            <div class="m-auto btn btn-warning rounded-pill" v-if="more_data" @click="getNextDatas">LOAD MORE</div>
        </div> 
    </b-tab>
</template>

<script>
import ArticleCard from './article-card.vue'

import {BTab, BSkeleton} from 'bootstrap-vue'

import store from '@/store'

export default {
    props: {
        genres: {
            type: String,
            default: ''
        },
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        BTab, BSkeleton,
        ArticleCard, 
    },
    watch: {
        genres(val) { 
            this.getNextDatas(1) 
            this.data_list = []
        },
        search: function (val) {
            if (!this.awaitingSearch) {
                setTimeout(() => { 
                    this.getNextDatas(1) 
                    this.data_list = []
                    this.awaitingSearch = false;
                }, 1000); // 1 sec delay
            }
            this.awaitingSearch = true;
        },
        currentLocale: {
            immediate: true,
            handler: function(val) {
                this.getNextDatas();
                this.data_list = []
                this.awaitingSearch = false;
            }
        },
    },
    computed: {
        currentLocale() {
            return this.$store.state.locales.CURRENT
        },
    }, 
    methods: {
        async getNextDatas(page=1){
            let temp = await store.dispatch(`articles/getDataList`, {
                page: page ? page : this.page,
                perpage: this.max_render,
                genres: this.genres.substring(0, this.genres.length - 1),
                search: this.search,
                lang: this.currentLocale
                // ...this.filter,
                // ...this.sort
            }) 
            this.data_list = this.data_list ? this.data_list.concat(temp.data) : temp.data
            this.more_data = ( this.page * this.max_render ) < temp.total
            this.page = this.page + 1
            this.$forceUpdate()
            this.init = false
        },
    },
    mounted(){  
        // this.getNextDatas()
    },
    data() {
        return {
            awaitingSearch: false,
            page: 1,
            max_render: 10,
            data_list: [],
            init: true,
            more_data: true
        };
    },
}
</script>