<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between">
        <router-link to="/insight" class="d-flex align-item-center text-primary mb-2">
            <i class="bx bx-left-arrow-circle font-weight-bolder" style="font-size:20px" />
            <h4 class="ml-1 font-weight-bolder mb-0 text-primary">Back</h4>
        </router-link>
        <h4 class="mb-0 font-weight-bolder text-primary">Articles</h4>
    </div>
    <div class="card" v-for="data in data_list" :key="data.article_id">
      <div class="card-body p-3">
        <div class="">
          <h2 class="text-primary font-weight-bolder">
            {{data.article_title}}
          </h2>
          <div class="d-flex align-items-center my-2">
            <img
                class="rounded-circle mr-2"
                style="width: 50px; height: 50px; object-fit: cover"
                :src="data.user_profilepict"
            />
            <div>
                <h6 class="text-truncate mb-0 font-weight-bolder">
                {{ data.user_fullname }}
                </h6>
                <label class="mb-0 text-nowrap">{{ moment(data.article_lastupdate).fromNow() }}</label>
            </div>
          </div>
          <img class="w-100 rounded mb-2" :src="data.article_image" />
          <div v-html="data.article_content" class="ql-editor p-0"></div>
        </div>

        
        <div class="d-flex flex-wrap mt-4" style="gap:15px">
            <span class="d-flex align-items-center">
                <i class="bx bxs-purchase-tag text-primary" style="margin-right:.5em;font-size: 20px;" />
                <h4 class="text-primary mb-0 font-weight-bolder">Tags :</h4>
            </span>
            <template v-for="genre in data.tags">
                <button
                    :key="genre.genre_id"
                    class="btn btn-sm btn-warning" 
                    style="color:white !important"
                    >
                    {{genre.genre_name}}
                </button>
            </template>
        </div>

        <hr style="border:1px solid #FF9F43" />
        <div class="d-flex flex-wrap align-items-center" style="gap:15px">
            <h5 class="text-primary mb-0 font-weight-bolder">Share with</h5>
            <div class="d-flex align-items-center text-primary" style="gap:10px;font-size:25px !important">
              <i class="bx bxl-whatsapp" style="cursor:pointer"></i>
              <i class="bx bxl-linkedin" style="cursor:pointer"></i>
              <i class="bx bxl-facebook" style="cursor:pointer"></i>
              <i class="bx bxl-telegram" style="cursor:pointer"></i>
            </div>
        </div>
        
        <hr style="border:1px solid #FF9F43" />
        <div class="d-flex align-items-center justify-content-between text-primary mt-1">
          <div class="d-flex align-items-center">
            <span class="d-flex align-items-center">
              <i class="bx bxl-instagram" style="font-size: 25px; margin-right: 0.25em"></i>
              {{data.views ? data.views : 0}}
            </span>
            <!-- <span class="d-flex align-items-center ml-2">
              <i
                class="bx bx-message-dots"
                style="font-size: 25px; margin-right: 0.25em"
              ></i>
              {{data.comments ? data.comments : 0}}
            </span> -->
          </div>
          <!-- <span class="d-flex align-items-center">
            <i class="bx bx-like" style="font-size: 25px; margin-right: 0.25em"></i>
            {{data.likes ? data.likes : 0}}
          </span> -->
        </div>

      </div>
    </div>

    <!-- <div class="card">
        <div class="card-body p-3">
            <h3 class="text-primary font-weight-bolder">
                Leave a Comment
            </h3>
            <hr style="border:1px solid #FF9F43" />
        </div>
    </div> -->

  </div>
</template>

<script>
import moment from "moment";
import store from '@/store'

export default {
  data() {
    return {
      moment, 
      data_list: []
    };
  },
  computed: {
      id: function () {
          return this.$route.params.id;
      }
  },
  watch: {
    id: {
        handler: function () { 
            this.getNextDatas(); 
        }, 
        immediate: true
    },
  },
  methods: {
      async getNextDatas(){
        let temp = await store.dispatch(`articles/getDataList`, {
            page: 1,
            perpage: 1, 
            id: this.id
        }) 
        this.data_list = temp.data 
        this.$forceUpdate() 
    },
  }
};
</script>
