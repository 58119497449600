<template>
<router-link :to="`insight/article/${data.article_id}`">
  <div class="card">
    <div class="card-body">

      <img class="w-100 rounded" :src="data.article_image" />
      <div class="">
        <h3 class="text-primary font-weight-bolder mt-2">
          {{ data.article_title }}
        </h3>
        <div class="d-flex flex-wrap mt-1" style="gap:10px"> 
            <template v-for="genre in data.tags">
                <button
                    :key="genre.genre_id"
                    class="badge badge-glow badge-warning"
                    style="border:unset"
                    >
                    {{genre.genre_name}}
                </button>
            </template>
        </div>
        <div class="d-flex align-items-center mb-1 mt-2">
          <img
            class="rounded- mr-2"
            style="width: 50px; height: 50px; object-fit: cover"
            :src="data.user_profilepict"
          />
          <div>
            <h6 class="text-truncate mb-0 font-weight-bolder">
              {{ data.user_fullname }}
            </h6>
            <label class="mb-0 text-nowrap">{{ moment(data.article_lastupdate).fromNow() }}</label>
          </div>
        </div> 
        <div v-html="stripHtml(data.article_content)" class="article-content text-secondary p-0 ql-editor"></div>
      </div>

      <div class="d-flex align-items-center justify-content-between text-primary mt-1">
        <div class="d-flex align-items-center">
          <span class="d-flex align-items-center">
            <i class="bx bxl-instagram" style="font-size: 25px; margin-right: 0.25em"></i>
            {{data.views ? data.views : 0}}
          </span>
          <!-- <span class="d-flex align-items-center ml-2">
            <i
              class="bx bx-message-dots"
              style="font-size: 25px; margin-right: 0.25em"
            ></i>
            {{data.comments ? data.comments : 0}}
          </span> -->
        </div>
        <!-- <span class="d-flex align-items-center">
          <i class="bx bx-like" style="font-size: 25px; margin-right: 0.25em"></i>
          {{data.likes ? data.likes : 0}}
        </span> -->
      </div>
      
    </div>
  </div>
</router-link>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
  },
};
</script>

<style>
.article-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
}
</style>
